<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else class="add-order-container" >
      <loading :loading="loading" />
      <v-card class="v-card-bottom-30 ">
        <v-card-title>
          <h4>ออเดอร์</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">นำเข้าออเดอร์ excel</span>
          <v-spacer/>
        </v-card-title>
      </v-card>

      <v-card class="v-card-bottom-30 hidden-sm-and-up">
        <v-card-text>
          <div align="center">
            <span style="color: red"><h2>ไม่รองรับหน้าจอ มือถือ !!</h2></span>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="v-card-bottom-30 hidden-xs-only">
        <v-card-title>
          <v-icon size="22" color="success darken-3">mdi mdi-home-assistant</v-icon><span class="ml-1">นำเข้าออเดอร์จาก Excel</span>
          <v-spacer/>
          <v-btn color="success">
            <v-icon left >mdi-download</v-icon>
            <a href="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/Excel/Import_Order_Template_1.xlsx"  style="color: white; text-decoration: none;" download>download template</a>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row jistify="center" align="center" class="pb-2">
            <v-col class="py-0" cols="12" md="2">
              Step 1 : อัพโหลดไฟล์
            </v-col>
            <v-col class="py-0" cols="12" md="4"  >
                   <v-file-input
                    v-model="file_excel"
                    ref="file_excel"
                    color="deep-purple accent-4"
                    placeholder="กรุณาเลือกไฟล์ excel เท่านั้น"
                    prepend-icon=""
                    outlined
                    dense
                    :disabled="!authorize" 
                    full-width
                    hide-details
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"

                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} file_excel(s)
                      </span>
                    </template>
                  </v-file-input>


            </v-col>
             <v-col class="py-0" cols="12" md="6" >
              </v-col>
          </v-row>
          <v-row jistify="center" align="center" class="pb-2">
            <v-col class="py-0" cols="12" md="2" >
              Step 2 : เลือกคลัง
            </v-col>
            <v-col class="py-0" cols="12"   md="4" >
              <v-select
                v-model="data_warehouse_id"
                :items="data_warehouse_list"
                :item-text="item => item.name"
                :item-value="item => item.id"
                dense hide-details
                outlined
                full-width

              ></v-select>
            </v-col>
             <v-col class="py-0" cols="12" md="6" >
              </v-col>
          </v-row>

          <v-row jistify="center" align="center" class="pb-2">
            <v-col class="py-0" cols="12" md="2">
              Step 3 : อัพโหลดออเดอร์
            </v-col>
            <v-col class="py-0" cols="12"  md="4">
              <v-btn :disabled="upload_status!=0 || !authorize" :loading="btn_upload_tmp_loading" color="info" outlined   @click="upload_to_update_tracking()"><v-icon left >mdi-upload</v-icon> upload</v-btn>
                  <span class="ml-2" v-if="btn_upload_tmp_loading == true">({{ row_num_excel +' / '+ row_max_excel }})</span>
            </v-col>
             <v-col class="py-0" cols="12" md="6" >
              </v-col>
          </v-row>
          <v-row jistify="center" align="center">
            <v-col class="py-0" cols="12" md="2">
              Step 4 : บันทึก
            </v-col>
            <v-col class="py-0" cols="12"  md="4">
              <v-btn v-if="upload_status==1"  :disabled="saving" outlined color="success"  @click="upload_order()" :loading="prosess_loading"><v-icon left >mdi-content-save</v-icon>บันทึก</v-btn>
                <span class="ml-2" v-if="prosess_loading == true">{{ "("+process_num + " / " +data_request_list_tmp.length+")" }}</span>
                <v-span v-if="upload_status==2"  class="green--text"  >อัพโหลดเรียบร้อย</v-span>

            </v-col>
             <v-col class="py-0" cols="12" md="6" >

              </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="v-card-bottom-30 hidden-xs-only">
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <span><h3>รายการออเดอร์</h3></span><br>
              <span>  จำนวนออเดอร์ : <span><b>{{ showdataTable.length }}</b></span> </span> /
              <span>  รออัพโหลด : <span style="color: blue"><b>{{sum_wait_upload}}</b></span> </span> /
              <span>  อัพโหลดสำเร็จ :<span style="color: green"><b>{{sum_success_upload}}</b></span>  </span> /
              <span>  อัพโหลดไม่สำเร็จ : <span style="color: red"><b>{{sum_false_upload}}</b></span>  </span><br>
            </v-col>
            <v-col cols="4" align="right">
              <div v-if="showdataTable.length > 0">
                <v-btn :disabled="upload_valid" color="danger" style="color: white" @click="CancelData()" outlined ><v-icon left >mdi-refresh</v-icon>เคลียร์ข้อมูล</v-btn>
                <!-- <v-btn :disabled="upload_valid" :loading="btn_information_loading" color="info" @click="prepareInformationData()">เตรียมข้อมูล</v-btn> -->
                <!-- <v-btn v-if="upload_sucsess_valid == false" :disabled="check_data_valid" color="success" class="ml-2" @click="upload_order()" :loading="prosess_loading">upload Order</v-btn> -->
              </div>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
           
              <span>  จำนวนออเดอร์ COD : <span><b>{{ Total_COD_order }}</b></span> </span> /
              <span>  ยอด COD รวม : <span style="color: red"><b>{{Total_COD_Amount}}</b></span> </span> /
              
            </v-col>
            <v-col cols="4" align="right">
              
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headersShow"
                :items="showdataTable"
                class="elevation-0 packhai-border-table"
                hide-default-footer
                :items-per-page="10000">
                <template v-slot:item.order_item="{ item }">
                  <a @click="dialog_show_order_item = true , dialogOrderItem = item.order_item_list">
                  <v-icon>mdi-list-box-outline</v-icon>
                </a>

                  <!-- <v-btn dark outlined color="primary" @click="dialog_show_order_item = true , dialogOrderItem = item.order_item_list">รายละเอียด</v-btn> -->
                </template>
                <template v-slot:item.sum_sku="{ item }">
                  {{ format_number( item.sum_sku ) }}
                </template>
                <template v-slot:item.sum_amount="{ item }">
                  {{ format_number( item.sum_amount ) }}
                </template>
                <template v-slot:item.totalPrice="{ item }">
                  {{ format_price( item.totalPrice ) }}
                </template>
                <template v-slot:item.codAmount="{ item }">
                  {{ format_price( item.codAmount ) }}
                </template>
                <template v-slot:item.deliveryCost="{ item }">
                  {{ format_price( item.deliveryCost ) }}
                </template>
                <template v-slot:item.unitPrice="{ item }">
                  {{ format_price( item.unitPrice ) }}
                </template>
                <template v-slot:item.statusUpload="{ item }">
                  <div v-if="item.num != null ">
                    <span v-if="item.isProcessSuccess == null" style="color: blue">รอ upload</span>
                    <span v-if="item.isProcessSuccess == true" style="color: green">สำเร็จ</span>
                    <span v-if="item.isProcessSuccess == false" style="color: red">ไม่สำเร็จ</span><br>
                    <span v-if="item.isProcessSuccess == false">{{item.errorDescription}}</span>
                  </div>
                </template>
                <template v-slot:item.deliveryDate="{ item }">
                  {{ formatDate_thai(item.deliveryDate) }}
                </template>

              </v-data-table>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>


      <v-dialog v-model="dialog_show_order_item" :fullscreen="isFullscreen"  scrollable max-width="60%" persistent >
        <v-card>
          <v-card-title >
            <span>แสดงรายการสินค้า</span>
            <v-spacer></v-spacer>
            <v-btn icon  @click="dialog_show_order_item = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="dialogHeadersShow"
                  :items="dialogOrderItem"
                  class="elevation-0 packhai-border-table"
                  hide-default-footer
                  :items-per-page="10000">


                  <template v-slot:item.num="{ item }">
                    {{ format_number( dialogOrderItem.indexOf(item)+1 ) }}
                  </template>
                  <template v-slot:item.totalPrice="{ item }">
                    {{ format_price( item.totalPrice ) }}
                  </template>
                  <template v-slot:item.codAmount="{ item }">
                    {{ format_price( item.codAmount ) }}
                  </template>
                  <template v-slot:item.deliveryCost="{ item }">
                    {{ format_price( item.deliveryCost ) }}
                  </template>
                  <template v-slot:item.unitPrice="{ item }">
                    {{ format_price( item.unitPrice ) }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

    </div>
</template>

<script>
    import * as easings from 'vuetify/es5/services/goto/easing-patterns'
    import Loading from '@/website/components/Loading'
    import Swal from 'sweetalert2'
    import axios from 'axios'
    import { format_number, format_order_id, format_price, isNumberWNoDot, isNumberWithDot, formatMoney, Trim_value, Trim_rules,get_languages,checkAuthorize, formatDate_thai} from '@/website/global_function'
    // import { shippingStatusDict } from '@/website/statusDict'
    import { orderService_dotnet, generalService_dotnet, logistic_Service, partnerService_dotnet, shopService_dotnet } from '@/website/global'
    import { token_api } from '@/website/backend/token'
    import { add_order_language } from "@/website/language/add_order_language";
    import readXlsxFile from 'read-excel-file'



   export default {
        components: {
            Loading,
        },

        data: () => ({
          page_loading: true,
          loading: false,
          authorize:false,

          btn_upload_tmp_loading: false,
          btn_information_loading: false,
          // edit for File excel
          file_excel: [],
          data_trackingNo_list: [],
          data_request_list_tmp : [],

          express_list: [],
          header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
          prosess_loading: false,
          process_num: 1,
          saving:false,
          disable_valid: false,
          upload_valid: false,
          upload_sucsess_valid: false,
          upload_status:0,
          check_data_valid: true,
          Total_COD_order:0,
          Total_COD_Amount:0,
          headersShow: [
            { text: 'ลำดับ', align: 'center', sortable: false, value: 'num' },
            { text: 'ดูสินค้า', align: 'center', value: 'order_item', sortable: false, width: '70px' },
            { text: 'สถานะ upload', align: 'center', value: 'statusUpload', sortable: false, width: '150px' },

            { text: 'จำนวน SKU', align: 'right', value: 'sum_sku', sortable: false , width: '90px' },
            { text: 'จำนวนสินค้า', align: 'right', value: 'sum_amount', sortable: false, width: '100px'  },

            { text: 'ชื่อ', align: 'left', sortable: false, value: 'name'  },
            { text: 'เบอร์โทร', align: 'center', sortable: false, value: 'phone', width:'90px' },
            { text: 'ที่อยู่', align: 'left', value: 'addressOriginal', sortable: false, width:'250px' },
            { text: 'ช่องทางการขาย', align: 'left', value: 'channelName', sortable: false, width: '150px' },
            { text: 'ขนส่ง', align: 'left', value: 'expressName', sortable: false, width: '130px' },
            { text: 'ยอด COD', align: 'right', value: 'codAmount', sortable: false , width: '90px' },
            { text: 'ยอดค่าประกัน', align: 'right', value: 'insuranceAmount', sortable: false , width: '90px' },
            { text: 'ยอดรวม', align: 'right', value: 'totalPrice', sortable: false, width: '90px'  },
            { text: 'ค่าขนส่ง', align: 'right', value: 'deliveryCost', sortable: false, width: '90px' },
            // { text: 'สร้างโดย', align: 'left', value: 'createdByStaffID', sortable: false, width:'130px' },

            // { text: 'สินค้า', align: 'left', value: 'productName', sortable: false, width:'130px' },
            // { text: 'บาร์โค้ด', align: 'left', value: 'barcode', sortable: false, width:'130px' },
            // { text: 'SKU', align: 'left', value: 'sku', sortable: false, width:'130px' },
            // { text: 'จำนวน', align: 'right', value: 'quantity', sortable: false, width:'130px' },
            // { text: 'ราคาต่อหน่วย', align: 'right', value: 'unitPrice', sortable: false, width:'130px' },

            { text: 'เลขอ้างอิง', align: 'center', value: 'referenceNumber', sortable: false  },
            { text: 'วันที่ส่งสินค้า', align: 'right', value: 'deliveryDate', sortable: false, width: '100px' },
          ],

          data_warehouse_id: null,
          data_warehouse_list: [],

          request_tmp : {
            "id": 0,
            "shopID": 0,
            "branchID": 0,
            "createdByStaffID": 0,
            "createdDatetime": null,
            "isDelete": true,
            "isProcessSuccess": null,
            "name": null,
            "phone": null,
            "addressOriginal": null,
            "address": null,
            "postcodeMappingID": null,
            "tumbon": null,
            "amphoe": null,
            "province": null,
            "postcode": null,
            "channelName": null,
            "expressName": null,
            "codAmount": null,
            "insuranceAmount": null,
            "totalPrice": null,
            "deliveryCost": null,
            "deliveryDate": null,
            "productName": null,
            "barcode": null,
            "sku": null,
            "quantity": null,
            "unitPrice": null,
            "referenceNumber": null,
            "orderGroupID": 0
          },

          postcode_mapping : [],

          req_tmp_list : [],
          showdataTable: [],

          row_max_excel : 0,
          row_num_excel : 0,

          check_max_excel : 0,
          check_num_excel : 0,

          check_num_excel_tmp : 0,

          dialog_show_order_item : false,
          isFullscreen : false,

          dialogHeadersShow: [
            { text: 'ลำดับ', align: 'center', sortable: false, value: 'num', width:'50px' },
            { text: 'สินค้า', align: 'left', value: 'productName', sortable: false },
            { text: 'บาร์โค้ด', align: 'left', value: 'barcode', sortable: false  },
            { text: 'SKU', align: 'left', value: 'sku', sortable: false  },
            { text: 'จำนวน', align: 'right', value: 'quantity', sortable: false, width:'60px' },
            { text: 'ราคา/หน่วย', align: 'right', value: 'unitPrice', sortable: false, width:'90px' },
          ],
          dialogOrderItem: [],

          sum_wait_upload : 0,
          sum_success_upload : 0,
          sum_false_upload : 0,
        }),

        destroyed() {
          // windowSize
          window.removeEventListener('resize', this.handleResize)
        },

        async created() {
          
          await this.get_postcode_mapping()
          await this.get_all_express()
          await this.get_order_upload()
          await this.get_warehouse_list()
          this.page_loading = false
           //เช็คสิทธิ์แก้ไขหรือดูได้อย่างเดียว
         this.get_authorize_staffshop();
        },

        computed: {

        },

        watch: {

        },

        methods: {
          format_number,
          format_order_id,
          format_price,
          isNumberWNoDot,
          isNumberWithDot,
          formatMoney,
          Trim_value,
          Trim_rules,
          get_languages,
          checkAuthorize,
          formatDate_thai,
          async upload_to_update_tracking () {
            this.btn_upload_tmp_loading = true
            if (this.file_excel == null){ this.file_excel = []}
            if (this.file_excel.length == 0) {

              this.$refs.file_excel.focus()
              this.btn_upload_tmp_loading = false
              return
            }
            var typeFile = false
            var file_type = this.file_excel['type']
            if(file_type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
              typeFile = true
            } else {
              this.$swal({
                type: 'warning',
                title: 'เกิดข้อผิดพลาดบางอย่าง',
                text: 'ประเภทไฟล์ไม่ถูกต้อง !!',
              })
              this.btn_upload_tmp_loading = false
              return
            }
            this.req_tmp_list = []

            if(typeFile){
              // อ่านค่าบนไฟล์ excel
                readXlsxFile(this.file_excel).then(async (rows) => {

                  if(rows.length == 0){
                    this.$swal({
                      type: 'warning',
                      // title: 'ไม่มีข้อมูล !!',
                      text: 'ไม่มีข้อมูล !!',
                    })
                    this.file_excel = null
                    this.btn_upload_tmp_loading = false
                    return
                  }

                  if(rows[0].length < 19){
                    this.$swal({
                      type: 'warning',
                      // title: 'คอลัมน์ excel มีไม่ครบหรือไม่ถูกต้อง !!',
                      text: 'คอลัมน์ excel มีไม่ครบหรือไม่ถูกต้อง !!',
                    })
                    this.file_excel = null
                    this.btn_upload_tmp_loading = false
                    return
                  }
                  if(rows[0][0] != "ชื่อ" && rows[0][18] != "วันที่ส่งสินค้า"){
                    this.$swal({
                      type: 'warning',
                      // title: 'template excel ไม่ถูกต้อง !!',
                      text: 'template excel  ไม่ถูกต้อง !!',
                    })
                    this.file_excel = null
                    this.btn_upload_tmp_loading = false
                    return
                  }

                  // for (var item_ck of rows) {
                  //   if(item_ck[12] == null || item_ck[12] == ''){
                  //     this.$swal({
                  //       type: 'warning',
                  //       // title: 'template excel ไม่ถูกต้อง !!',
                  //       text: 'กรุณากรอก เลขอ้างอิง ทุกออเดอร์ !!',
                  //     })
                  //     this.file_excel = null
                  //     this.btn_upload_tmp_loading = false
                  //     return
                  //   }
                  // }


                  var data_trackingNo_list = []
                  var order_group_id = 0
                  this.row_max_excel = rows.length
                  this.row_num_excel = 0

                  for(var i=0; i<rows.length; i++){
                    this.row_num_excel = i + 1
                    if(i > 0){

                      if((rows[i][13]==null || rows[i][13] == '') && (rows[i][14]==null || rows[i][14] == ''))
                      {
                        continue;
                      }
                      if(rows[i][0]!=null && rows[i][0]!=''){
                        if(i!=1 && rows[i-1][0] == rows[i][0]){
                          // order_group_id = order_group_id ;
                          if(rows[i][17]!=null && rows[i][17]!='' && rows[i-1][17] != rows[i][17]){
                            order_group_id += 1;
                          }
                        }else{
                          // CurrentUUID = new yyyyMMddHHmmss-shopid-running numbe
                          order_group_id += 1;
                        }
                      }

                      var address_tmp = null
                      var postcodeMappingID_tmp = null
                      var tumbon_tmp = null
                      var amphoe_tmp = null
                      var province_tmp = null
                      var postcode_tmp = null

                      var totalPrice_tmp = null
                      var quantity_tmp = null
                      var unitPrice_tmp = null
                      var deliveryCost_tmp = null
                      var codAmount_tmp = null
                      var insuranceAmount_tmp = null
                      var deliverDate_tmp = null
                      var phone_tmp = null
                      if(rows[i][1] != null){
                        phone_tmp = rows[i][1].toString()
                        if(phone_tmp.length > 0){
                          if(phone_tmp.substring(0, 1) != '0'){
                            phone_tmp = '0' +phone_tmp
                          }
                        }
                      }
                      var province_res_tmp = null
                      var addressToBeSplited = rows[i][0]+ ' ' + phone_tmp+' ' +rows[i][2]

                      // if(rows[i][2] != null){
                      //   province_res_tmp = await this.address_api_click(addressToBeSplited)

                      // }

                      if(rows[i][11] != null){
                        totalPrice_tmp = rows[i][11].toString()
                      }

                      if(rows[i][15] != null){
                        quantity_tmp = rows[i][15].toString()
                      }

                      if(rows[i][16] != null){
                        unitPrice_tmp = rows[i][16].toString()
                      }

                      if(rows[i][12] != null){
                        deliveryCost_tmp = rows[i][12].toString()
                      }

                      if(rows[i][10] != null){
                        insuranceAmount_tmp = rows[i][10].toString()
                      }

                      if(rows[i][9] != null){
                        codAmount_tmp = rows[i][9].toString()

                        if(codAmount_tmp!="" && codAmount_tmp!=""){
                          try {
                            this.Total_COD_Amount+= parseFloat(codAmount_tmp)
                            if(parseFloat(codAmount_tmp)>0)
                              this.Total_COD_order++
                          } catch (error) {
                              var g=0
                          }
                        }
                      }


                      
                      if(province_res_tmp != null){
                        address_tmp = province_res_tmp.address
                        postcodeMappingID_tmp = province_res_tmp.Id
                        tumbon_tmp = province_res_tmp.SubDistrict
                        amphoe_tmp = province_res_tmp.District
                        province_tmp = province_res_tmp.Province
                        postcode_tmp = province_res_tmp.Postcode
                      }

                      if (rows[i][18] != null && rows[i][18] != '') {
                        var countChar = rows[i][18].toString().length
                        var formatDate = null

                        if (countChar <= 10) {
                          var format = rows[i][18].split("/")
                          if (format.length > 1) {
                            var date = parseInt(format[0]) < 10 ? '0' + format[0] : format[0]
                            var month = parseInt(format[1]) < 10 ? '0' + format[1] : format[1]
                            var year = format[2]
                            formatDate = year + '-' + month + '-' + date
                          }
                        }

                        deliverDate_tmp = formatDate == null ? new Date(rows[i][18]).toISOString().split("T")[0] : formatDate
                      }

                      var tb = rows[i][3] != null && rows[i][3] != '' ? ' ' + rows[i][3] : ''
                      var ap = rows[i][4] != null && rows[i][4] != '' ? ' ' + rows[i][4] : ''
                      var pv = rows[i][5] != null && rows[i][5] != '' ? ' ' + rows[i][5] : ''
                      var pc = rows[i][6] != null && rows[i][6] != '' ? ' ' + rows[i][6] : ''
                      var addOrg = rows[i][2] + tb + ap + pv + pc

                      this.request_tmp = {
                        "id": i,
                        "shopID": parseInt(localStorage.getItem('shop_id')),
                        "branchID": parseInt(localStorage.getItem('branch_id')),
                        "createdByStaffID": parseInt(localStorage.getItem('staff_id')),
                        "createdDatetime": null,
                        "isDelete": false,
                        "isProcessSuccess": false,
                        "name": (rows[i][0] != null && rows[i][0] != '') ? rows[i][0].toString() : null,
                        "phone": phone_tmp,
                        "addressOriginal": addOrg,
                        "address": (rows[i][2] != null && rows[i][3] != '') ? rows[i][2].toString() : null,
                        "postcodeMappingID": postcodeMappingID_tmp,
                        "tumbon": (rows[i][3] != null && rows[i][3] != '') ? rows[i][3].toString() : null,
                        "amphoe": (rows[i][4] != null && rows[i][4] != '') ? rows[i][4].toString() : null,
                        "province": (rows[i][5] != null && rows[i][5] != '') ? rows[i][5].toString() : null,
                        "postcode": (rows[i][6] != null && rows[i][6] != '') ? rows[i][6].toString() : null,
                        "channelName": rows[i][7],
                        "expressName": rows[i][8],
                        "codAmount": codAmount_tmp,
                        "insuranceAmount": insuranceAmount_tmp,
                        "totalPrice": totalPrice_tmp,
                        "deliveryCost": deliveryCost_tmp,
                        "productName": rows[i][13],
                        "barcode": null,
                        "sku": (rows[i][14] != null && rows[i][14] != '') ? rows[i][14].toString() : null,
                        "quantity": quantity_tmp,
                        "unitPrice": unitPrice_tmp,
                        "referenceNumber":  (rows[i][17] != null && rows[i][17] != '') ? rows[i][17].toString() : null,
                        "deliveryDate": deliverDate_tmp,
                        "orderGroupID": order_group_id
                      }
                      ;
                      this.req_tmp_list.push(this.request_tmp)

                    }


                  }

                  if(this.req_tmp_list.length > 0){
                    try {
                      let res1 = await axios.post(
                        orderService_dotnet+'Order/insert-order-excel-tmp',
                        {
                          "req": this.req_tmp_list
                        } , { headers: this.header_token})
                      if(res1.status!=200){
                        this.$swal({
                          type: 'warning',
                          title: 'เกิดข้อผิดพลาดบางอย่าง กรุณาลองอีกครั้ง'

                        })
                      }
                    } catch (e) {
                      this.$swal({
                        type: 'warning',
                        title: 'insert-order-excel เกิดข้อผิดพลาดบางอย่าง',
                        text: e.message,
                      })
                    }
                  }
                  this.get_order_upload()
                  this.btn_upload_tmp_loading = false

                })

            }


          },

          async address_api_click(data_tmp) {
              try {
                const params = new URLSearchParams()
                params.append('address', "'" + data_tmp + "'")

                const headers = {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Auth-Type": "WEB",
                    "X-Lan": "TH"
                }
                let response = await axios.post("https://api.best-inc.co.th/addressee/parse", params, headers)
                if (response.data.success) {
                    const data = response.data.data

                    var isValid = true
                    if (data.candidates.length == 0) {
                        isValid = false
                    }
                    if(isValid){

                      // เช็ค province ว่ามีหลายจังหวัดหรือเปล่า ถ้ามีหลายจังหวัดไม่เอา เพราะอาจจะแยกที่อยู่ผิดพลาด
                      var province =[]
                      data.candidates.forEach(element => {
                        province.push(element.province.name )
                      });
                      var uniqueCount = province.filter((value, index, self)=>{
                        return self.indexOf(value) === index;
                      }).length
                      if(uniqueCount==1){
                        const address_extract = data.candidates[0]
                        const postcode_mapping_result = this.postcode_mapping.find(
                                ({SubDistrict, District, Province, Postcode}) =>
                                SubDistrict === address_extract.district.name &&
                                District === address_extract.city.name &&
                                Province === address_extract.province.name &&
                                Postcode === data.postcode
                        )
                        postcode_mapping_result['address'] = data.address
                        return postcode_mapping_result ;
                      }else{
                        isValid=false
                      }
                    }

                }
              } catch (e) {
                console.log(e.message);
                // this.$swal({
                //   type: 'warning',
                //   title: 'แยกที่อยู่เกิดข้อผิดพลาดบางอย่าง',
                //   text: e.message,
                // })
              }
          },

          async get_order_upload () {

            try {
              let res1 = await axios.post(
                orderService_dotnet+'Order/get-order-excel-tmp',
                {
                  "shopID": parseInt(localStorage.getItem('shop_id'))
                } , { headers: this.header_token})

              this.Total_COD_Amount=0;
              this.Total_COD_order=0;
              this.showdataTable = []
              var num_tmp = 1
              var GroupID_tmp = null


              var order_item_list = []
              var order_item = {}
              var address_tmp = ''
              for (var item of res1.data) {


               
                if(GroupID_tmp != item.orderGroupID){

                  item['num'] = num_tmp
                  GroupID_tmp = item.orderGroupID

                  item['order_item_list'] = []
                  item['sum_sku'] = 0
                  item['sum_amount'] = 0
                  address_tmp = item.address + (item.tumbon == null ? '' : ' ' + item.tumbon) + (item.amphoe == null ? '' : ' ' + item.amphoe) + (item.province == null ? '' : ' ' + item.province) + (item.postcode == null ? '' : ' ' + item.postcode)
                  item.addressOriginal = item.address == null ? item.addressOriginal : address_tmp
                  if(item['totalPrice']==null)
                    item['totalPrice'] = 0
                  this.showdataTable.push(item)
                  num_tmp = num_tmp+1
                }

                order_item = {
                  "productName": item.productName,
                  "barcode": item.barcode,
                  "sku": item.sku,
                  "quantity": item.quantity,
                  "unitPrice": item.unitPrice,
                  "orderGroupID": item.orderGroupID
                }
                order_item_list.push(order_item)

              }

              // จัดกลุ่ม ข้อมูล
              var num_tmp1 = 0
              var sum_sku = 0
              var sum_amount = 0

              this.sum_wait_upload = 0
              this.sum_success_upload = 0
              this.sum_false_upload = 0
              for (var order_set of this.showdataTable) {
                
                if( order_set.codAmount !=null){
                    try {
                      this.Total_COD_Amount+= parseFloat(order_set.codAmount)
                      if(parseFloat(order_set.codAmount)>0)
                        this.Total_COD_order++
                    } catch (error) {
                      var g=0
                    }
                }

                if(order_set.isProcessSuccess == null){
                  this.sum_wait_upload += 1
                }

                if(order_set.isProcessSuccess == true){
                  this.sum_success_upload += 1
                }

                if(order_set.isProcessSuccess == false){
                  this.sum_false_upload += 1
                }

                var tempTotalPrice=0;
                for (var item_set of order_item_list) {
                  if(order_set.orderGroupID == item_set.orderGroupID ){
                    order_set.order_item_list.push(item_set)
                    order_set.sum_sku += 1

                    if(item_set.quantity == null || item_set.quantity == ''){
                      item_set.quantity = 0
                    }else {
                      item_set.quantity = Number(item_set.quantity)
                    }
                    order_set.sum_amount = order_set.sum_amount + item_set.quantity

                    var unitprice=0;
                    if(item_set.unitPrice != null && item_set.unitPrice != ''){
                      unitprice = parseFloat(item_set.unitPrice);
                    }
                    
                    tempTotalPrice = parseFloat(tempTotalPrice) + (parseFloat(item_set.quantity) * unitprice);
                  }
                }
                if(tempTotalPrice !=0){
                  order_set.totalPrice=tempTotalPrice;
                }
              }

              if(this.showdataTable.length == 0){
                this.disable_valid = false
              }else {
                this.disable_valid = true
              }

              if(this.showdataTable.length == this.sum_success_upload && this.sum_success_upload>0){
                this.upload_status=2
              }
              else if(this.showdataTable.length>0){
                this.upload_status=1
              }else{
                this.upload_status=0
              }


            } catch (e) {
              this.$swal({
                type: 'warning',
                title: 'ดึงข้อมูลอัพโหลด excel เกิดข้อผิดพลาดบางอย่าง',
                text: e.message,
              })
            }

          },

          async prepareInformationData () {
            // this.btn_information_loading = true

            if(this.showdataTable.length == 0){
              // this.btn_information_loading = false
              return
            }

            this.check_num_excel = 1

            try {
                this.prosess_loading = true

                //#region  Initialize object
                var data_request_list = []
                var data_request = {
                  "referenceNumber": null,
                  "isCod": false,
                  "codAmount": 0,
                  "insuranceAmount": 0,
                  "receiverName": null,
                  "receiverPhone": null,
                  "receiverAddress": null,
                  "receiverSubDistrict": null,
                  "receiverDistrict": null,
                  "receiverProvince": null,
                  "receiverPostcode": null,
                  "expressCompanyName": 0,
                  "trackingNo": null,
                  "sortCode1": null,
                  "sortCode2": null,
                  "sortCode3": null,
                  "totalProductPrice": 0,
                  "deliveryPrice": 0,
                  "deliveryDate": null,
                  "orderDiscount": 0,
                  "vatType": 0,
                  "vatAmount": 0,
                  "remark": null,

                  "partnerID": "0",
                  "partnerKey": "0",
                  "partnerName": "Packhai",
                  "shopID": 0,
                  "referenceSourceName": 0,
                  // "channelName" : "Packhai Upload",
                  "salemanID": 0,
                  "createdBy": 0,
                  "warehouseID": 0,

                  "productList": []

                }
                var product_List = {
                    "name": null,
                    "propDescription": null,
                    "sku": null,
                    "barcode": null,
                    "quantity": 0,
                    "unitPrice": 0,
                    "productImageUrl": null
                }
                //#endregion

                for (var item of this.showdataTable)
                {
                  if(item.isProcessSuccess){
                    continue;
                  }
                  // prosess start
                  var IsCOD = false
                  if(item.codAmount != null){
                    IsCOD = true
                  }

                  var codAmount_tmp = null
                  var deliveryPrice_tmp  = null
                  var totalProductPrice_tmp = null
                  var orderDiscount_tmp = null
                  var vatAmount_tmp = null
                  var insuranceAmount_tmp = null

                  if(item.codAmount != null){
                    codAmount_tmp =  Number(item.codAmount)
                  }
                  if(item.deliveryCost != null){
                    deliveryPrice_tmp =  Number(item.deliveryCost)
                  }
                  if(item.totalPrice != null){
                    totalProductPrice_tmp =  Number(item.totalPrice)
                  }
                  if(item.insuranceAmount != null){
                    insuranceAmount_tmp =  Number(item.insuranceAmount)
                  }
                  let delivery=null;
                  if(item.deliveryDate){
                    var splited = item.deliveryDate.split(' ')[0].split('-'); 
                    delivery = splited[0]+"-"+splited[1]+"-"+splited[2]
                  }
                  data_request = {
                    "referenceNumber": item.referenceNumber,
                    "isCod": IsCOD,
                    "codAmount": codAmount_tmp,
                    "insuranceAmount": insuranceAmount_tmp,
                    "receiverName": item.name,
                    "receiverPhone": item.phone,
                    "receiverAddress": item.address,
                    "receiverSubDistrict": item.tumbon,
                    "receiverDistrict": item.amphoe,
                    "receiverProvince": item.province,
                    "receiverPostcode": item.postcode,
                    // "expressID": 0,
                    "expressCompanyName": item.expressName,
                    "trackingNo": null,
                    "sortCode1": null,
                    "sortCode2": null,
                    "sortCode3": null,
                    "totalProductPrice": totalProductPrice_tmp,
                    "deliveryPrice": deliveryPrice_tmp,
                    "deliveryDate": delivery,
                    "orderDiscount": 0,
                    "vatType": 1,
                    "vatAmount": 0,
                    "remark": null,
                    "partnerID": "0",
                    "partnerKey": "0",
                    "partnerName": "Packhai",
                    "shopID": parseInt(localStorage.getItem('shop_id')),
                    "referenceSourceName": item.channelName,
                    // "channelID": 0,
                    // "channelName" : "Packhai Upload",
                    "salemanID": Number(localStorage.getItem('staff_id')),
                    "createdBy": Number(localStorage.getItem('staff_id')),
                    "warehouseID": this.data_warehouse_id,
                    "productList": [],
                    "OrderGroupID" : item.orderGroupID
                  }

                  if(item.num != null && item.postcodeMappingID == null){
                    data_request.receiverAddress = item.addressOriginal;
                  }

                  var product_List_s = []

                  for (var param1 of item.order_item_list) {
                    product_List = {
                        "name": param1.productName,
                        "propDescription": null,
                        "sku": param1.sku,
                        "barcode": param1.barcode,
                        "quantity": Number(param1.quantity),
                        "unitPrice": Number(param1.unitPrice),
                        "productImageUrl": null
                    }
                    product_List_s.push(product_List)
                  }
                  data_request.productList = JSON.parse(JSON.stringify(product_List_s))
                  data_request_list.push(data_request);
                }

                this.data_request_list_tmp = data_request_list

            } catch (e) {
              this.upload_valid = false
              this.prosess_loading = false
              Swal.fire({
                position: "top",
                type: "error",
                title: "เกิดข้อผิดพลาด !!",
                text: e.message,
              })
            }
          },

          async upload_order () {

            this.saving=true;
            await this.prepareInformationData()

            for (var item of this.data_request_list_tmp) {
              
              let res1 ;
              try {
                res1 = await axios.post(partnerService_dotnet+'Order/new-order-internal', item , { headers: this.header_token})
              } catch (e) {
                this.$swal({
                  type: 'warning',
                  title: 'new-order-internal เกิดข้อผิดพลาดบางอย่าง',
                  text: e.message,
                })
                res1['data']['orderID'] = null
              }
              try {
                if(res1.data.orderID != '0'){
                  let res2 = await axios.post(orderService_dotnet+'Order/update-status-upload-order-excel', {
                        "shopID": parseInt(localStorage.getItem('shop_id')),
                        "orderGroupID": item.OrderGroupID,
                        "orderID" : res1.data.orderID,
                        "errorDescription": null,
                        "status": true,
                        "isClose": false
                  } , { headers: this.header_token})
                }else {
                  let res3 = await axios.post(orderService_dotnet+'Order/update-status-upload-order-excel', {
                        "shopID": parseInt(localStorage.getItem('shop_id')),
                        "orderGroupID": item.OrderGroupID,
                        "orderID": null,
                        "errorDescription": res1.data.error_description,
                        "status": false,
                        "isClose": false
                  } , { headers: this.header_token})
                }
              } catch (e) {
                this.$swal({
                  type: 'warning',
                  title: 'update-status-upload-order เกิดข้อผิดพลาดบางอย่าง',
                  text: e.message,
                })
              }

              await this.get_order_upload()
              this.process_num += 1
            }

            if(this.showdataTable.length == this.sum_success_upload && this.sum_success_upload>0){
                this.upload_status=2
              }
              else if(this.showdataTable.length>0){
                this.upload_status=1
              }else{
                this.upload_status=0
              }
            this.prosess_loading = false
            this.saving=false;
          },



          async CancelData () {
            if(this.showdataTable.length == 0){
              return
            }

            try {
              this.loading = true
              let res1 = await axios.post(orderService_dotnet+'Order/delete-order-excel-tmp',{
                "shopID": parseInt(localStorage.getItem('shop_id'))
              } , { headers: this.header_token})
              this.loading = false
              this.disable_valid = false
              this.upload_valid = false
              this.upload_status=0
              this.check_data_valid = true
              this.file_excel = null
              this.saving=false
              this.Total_COD_Amount=0
              this.Total_COD_order=0
              this.get_order_upload()
            } catch (e) {
              this.$swal({
                type: 'warning',
                title: 'delete-order-excel เกิดข้อผิดพลาดบางอย่าง',
                text: e.message,
              })
            }
          },

          async get_all_express () {
            try {
              let res1 = await axios.post(logistic_Service+'Logistic/get-express-list',{} , { headers: this.header_token})
              this.express_list = res1.data
            } catch (e) {
              this.$swal({
                type: 'warning',
                title: 'get-express-list เกิดข้อผิดพลาดบางอย่าง',
                text: e.message,
              })
            }
          },
          async get_authorize_staffshop () {
            this.authorize =  this.checkAuthorize(26);
          // if(localStorage.getItem("340") == 2 )
          // {
          //  this.authorize = true
          // }
        },


          async get_postcode_mapping () {
            var postcode_mapping = JSON.parse(localStorage.getItem('postcodeMapping_dotnet'))
            if(postcode_mapping === 'undefined'){
              postcode_mapping = null
            } else if ( postcode_mapping == 'null') {
              postcode_mapping = null
            } else if ( postcode_mapping == null) {
              postcode_mapping = null
            } else if (postcode_mapping == '') {
              postcode_mapping = null
            }
            if(postcode_mapping != null){
              this.postcode_mapping = postcode_mapping
            } else {
              try {
                await axios.post(generalService_dotnet+'General/get-postcodemapping', {},
                { headers: this.header_token})
                .then(response =>
                {
                  localStorage.setItem('postcodeMapping_dotnet', JSON.stringify(response.data))
                  this.postcode_mapping = response.data
                })
              } catch (e) {
                this.$swal({
                  type: 'warning',
                  title: 'get-postcodemapping เกิดข้อผิดพลาดบางอย่าง',
                  text: e.message,
                })
              }
            }
          },

          async get_warehouse_list () {
            let res1 = await axios.post(shopService_dotnet + 'Shop/get-warehouse-list',{
              "shopID": parseInt(localStorage.getItem('shop_id')),
            } , { headers: this.header_token})
            this.data_warehouse_list = res1.data
            if(this.data_warehouse_list.length > 0){
              this.data_warehouse_id = this.data_warehouse_list[0].id
            }
          },

        }
    }
</script>

<style scoped>
    >>>.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
        transform: none;
    }

    >>>.v-select__selection--comma {
        white-space: normal;
    }
</style>
